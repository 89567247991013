<template>
  <b-overlay :show="loadingPrice || loadingServiceRequested" rounded="sm">
    <h4 class="text-primary">{{ $t("services.requests") }}</h4>
    <b-form-row>
      <b-col lg="8">
      <b-form @submit.stop.prevent="onFormSubmit">
        <!-- Service -->
        <b-form-row>
          <b-col lg="12">
            <b-form-group
              id="service-input-group"
              :label="$t('services.service')"
              label-for="service"
            >
              <multiselect
                id="service"
                :close-on-select="true"
                :clear-on-select="false"
                :aria-autocomplete="false"
                :multiple="false"
                :allow-empty="false" 
                :placeholder="$t('select_placeholder')"
                :select-label="$t('multiselect.selectLabel')"
                :selected-label="$t('multiselect.selectedLabel')"
                track-by="id"
                :options="services"
                v-model="$v.form.service.$model"
                :state="validateState('service')"
                aria-describedby="service-live-feedback"
                :reset-after="true"
                @select="addToCart($event)"
              >
                <template slot="noOptions">
                  {{ $t("multiselect.noOptionsLabel") }}
                </template>
                <template slot="noResult"
                  >{{ $t("multiselect.noResultLabel") }}
                </template>

                <template slot="option" slot-scope="{ option }">
                  {{ option.name }}
                </template>

                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.name }}
                </template>
              </multiselect>
              <b-form-invalid-feedback id="service-live-feedback">
                {{ $t("validation.required") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <!-- Service Description -->
        <b-form-row v-if="form.service" class="mb-2">
          <b-col lg="12">
            <div class="p-2 bg-secondary text-white">
              {{ form.service.description }}
            </div>
          </b-col>
        </b-form-row>

        <!-- Service Description -->
        <b-form-row v-if="serviceDescription" class="mb-2">
          <b-col lg="12">
            <p>{{ serviceDescription }}</p>
          </b-col>
        </b-form-row>

        <hr class="my-2" style="border-top: 1.5px solid rgb(200, 200, 200);" >
        <br>

        <!-- Client + Patient -->
        <b-form-row>
          <b-col lg="6">
            <b-form-group
              id="family_user-input-group"
              :label="$t('services.user_id')"
              label-for="family_user"
            >
              <multiselect
                  id="family_user"
                  :class="{ 'is-invalid': $v.form.family_user.$error }"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :aria-autocomplete="false"
                  :multiple="false"
                  :placeholder="$t('select_placeholder')"
                  :select-label="$t('multiselect.selectLabel')"
                  :selected-label="$t('multiselect.selectedLabel')"
                  :deselect-label="$t('multiselect.deselectLabel')"
                  track-by="famiglia_id"
                  :options="families"
                  v-model="$v.form.family_user.$model"
                  :state="validateState('family_user')"
                  aria-describedby="family_user-live-feedback"
              >
                <template slot="noOptions">
                  {{ $t("multiselect.noOptionsLabel") }}
                </template>
                <template slot="noResult"
                >{{ $t("multiselect.noResultLabel") }}
                </template>

                <template slot="option" slot-scope="{ option }">
                  {{ option.first_name + " "+option.last_name}}
                </template>

                <template slot="singleLabel" slot-scope="{ option }">
                  {{option.first_name + " "+option.last_name}}
                </template>
              </multiselect>
                <b-form-invalid-feedback id="family_user-live-feedback">
                  {{ $t("validation.required") }}
                </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="button_add_family-input-group"
              :label="$t('services.add_family')"
              label-for="button_add_family"
            >
            <b-button id="button_add_family" @click="showModalRegister">+</b-button>
            </b-form-group>
          </b-col>
        </b-form-row>
        <!-- City + Address -->
        <b-form-row>
          <b-col lg="6">
            <b-form-group
              id="affiliate_user-input-group"
              :label="$t('services.affiliate_family')"
              label-for="affiliate_user"
            >
            <input type="hidden" v-model="$v.form.affiliate_user.$model"/>
              <b-form-input
                id="affiliate_user"
                type="text"
                :value="$loggedUser().first_name + ' ' + $loggedUser().last_name"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <!-- Affiliate + Price -->
        <b-form-row>
          <b-col lg="6">
            <b-form-group
              id="address-input-group"
              :label="$t('services.address')"
              label-for="address"
            >
              <b-form-input
                id="address"
                type="text"
                v-model="$v.form.address.$model"
                :state="validateState('address')"
                aria-describedby="address-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="address-live-feedback">
                <div v-if="!$v.form.address.required">
                  {{ $t("validation.required") }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group
              id="assisted_name-input-group"
              :label="$t('services.assisted_name')"
              label-for="assisted_name"
            >
              <b-form-input
                id="assisted_name"
                type="text"
                v-model="$v.form.assisted_name.$model"
                :state="validateState('assisted_name')"
                aria-describedby="assisted_name-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="assisted_name-live-feedback">
                <div v-if="!$v.form.assisted_name.required">
                  {{ $t("validation.required") }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <!-- Actions -->
        <div class="text-right">
          <b-button type="submit" variant="success" :disabled="isCartEmpty"
            >{{ $t("buttons.service_request") }}
          </b-button>
        </div>
      </b-form>
      </b-col>
  

      <b-col lg="3" class="ml-4 text-center" style="border-left: 1px solid rgb(200, 200, 200);">
        <h4 class="text-primary">{{ $t("services.cart") }}</h4>
        <!-- Carrello -->
        <div class="cart">
          <ul>
            <li v-for="(cartItem, index) in cart" :key="index" class="cart-item">
              {{ cartItem.service.name }} (x{{ cartItem.quantity }})
              <b-button class="custom-btn" variant="danger" @click="removeFromCart(index)">
                <b-icon-trash></b-icon-trash> 
              </b-button>
            </li>
          </ul>
        </div>
      </b-col>
    </b-form-row>

  

    <!-- Modale anteprima richiesta servizio -->
    <b-modal
      id="modal-confirmation"
      title="Riepilogo"
      ok-title="Conferma"
      cancel-title="Annulla"
      @ok="onModalConfirm"
    >
      <!-- <p class="my-4">
        Servizio: <strong>ahaha</strong>
      </p> -->
      <p class="my-4">
        Prezzo: <strong>{{ this.price }}&euro;</strong>
      </p>
      <p class="my-4">
        Assistito: <strong>{{ this.form.assisted_name }}</strong>
      </p>
    </b-modal>
  </b-overlay>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
// import router from "@/router";
import { fields, processStatuses } from "@/services/endpoints";
import router from "@/router";
// import i18n from "@/i18n";

export default {
  mixins: [validationMixin],
  name: "RequestServiceFormAffiliate",
  props: {
    services: {
      type: Array,
      required: true,
    },
    service: {
      type: Object,
      default: null,
    },
    watch: {
    // Watching form.affiliate_user for changes
      'form.affiliate_user'(newValue, oldValue) {
       console.log('Affiliate User changed from', oldValue, 'to', newValue);
      },
    }
    // city: {
    //   type: Object,
    //   default: null,
    // },
  },
  data() {
    return {
      form: this.getDefaultFormData(),
      fields: fields,
      weekendService: false,
      serviceDescription: null,
      cart: this.getDefaultCart(),
      dataPrice: null,
      list_services: []
    };
  },
  computed: {
    ...mapGetters({
      families: "families/families",
      // cities: "cities/cities",
      loadingAffiliates: "services/loadingAffiliates",
      affiliates: "services/affiliates",
      loadingPrice: "services/loadingPrice",
      price: "services/price",
      serviceRequested: "serviceRequests/serviceRequested",
      loadingServiceRequested: "serviceRequests/loadingServiceRequested",
      loadingFamily: "services/"
    }),
    isCartEmpty() {
      // Questa computed property determina se il carrello è vuoto o meno
      return this.cart.length === 0;
    },
  },
  watch: {
    serviceRequested: function (newValue) {
      if (newValue) {
        // Go to service requests page
        router.push({
          name: "service_requests",
        });
      }
    },
  },
  validations: {
    form: { 
      service: { },
      affiliate_user: {
        required,
      },
      assisted_name: {
        required,
      },
      address: {
        required,
      },
      family_user: {
        required,
      },
    },
    cart: {
      required
    }
  },
  methods: {
    ...mapActions({
      getFamilies: "families/getAll",
      getCities: "cities/getCities",
      getServices: "services/getAll",
      // getAffiliatesByCityId: "services/getAffiliatesByCityId",
      resetAffiliates: "services/resetAffiliates",
      postPriceByServiceAndAffiliate: "services/postPriceByServiceAndAffiliate",
      // resetPrice: "services/resetPrice",
      requestService: "serviceRequests/requestService",
      showError: "alert/error",
    }),
    getDefaultFormData() {
      console.log("Is Logged:",this.$isLogged());
      console.log("Logged User:", this.$loggedUser());
      const affiliate_user = this.$isLogged() ? this.$loggedUser().pk : null; 
      console.log("Affiliate User:", affiliate_user)
      return {
        // service: this.service
        //affiliate_user: this.$isLogged() ? this.$loggedUser()?.utente?.id : null,
        affiliate_user: affiliate_user,
        //affiliate_user: this.$isLogged() ? this.$loggedUser().utente.id : null,
        assisted_name: null,
        address: null,
        caretaker_user: null,
        family_user: null,
        mail_sended: false,
        user: this.$isLogged() ? this.$loggedUser().pk : null,
      };
    },
    getDefaultCart(){
      var cart = []
      if (this.service){
        cart.push({ service: this.service, quantity: 1 })
      }
      return cart
    },
    getResetFormData() {
      console.log("Questo e' l'id utente da l'altra funzione")
      console.log(user.id)
      return {
        service: this.form.service,
        affiliate_user: this.$isLogged() ? this.$loggedUser().id : null,
        assisted_name: null,
        address: null,
        caretaker_user: null,
        family_user: null,
        mail_sended: false,
        user: this.$isLogged() ? this.$loggedUser().pk : null,
      };
    },
    updateServiceDescription() {
      if (!this.form.service) {
        this.serviceDescription = "Selezionare il servizio desiderato";
        return;
      }
      this.serviceDescription =
        "Selezionare data e ora di inizio e fine del servizio desiderato";
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
  async onFormSubmit() {
  this.$v.form.$touch();
  if (this.$v.form.$anyError) {
    // Log a general message
    console.log(affiliate_user)
    console.log("Form contains validation errors");

    // Iterate over each field in the form validation object
    Object.keys(this.$v.form).forEach(fieldName => {
      // Check if the field has been touched and has an error
      if (this.$v.form[fieldName].$dirty && this.$v.form[fieldName].$anyError) {
        console.log(`Validation error in field: ${fieldName}`);

        // Iterate over each validator applied to the field
        Object.keys(this.$v.form[fieldName]).forEach(validator => {
          // Check if the validator has failed
          if (this.$v.form[fieldName][validator] === false) {
            console.log(` - Validator failed: ${validator}`);
          }
        });
      }
    });

    return;
  }

    // async onFormSubmit() {
    //   this.$v.form.$touch();
    //   if (this.$v.form.$anyError) {
    //     console.log("Form errors", this.$v.form);
    //     return;
    //   }
      // Get price from APIs
      console.log(this.$loggedUser())
      console.log(this.service)
      // this.serviceModal = this.form.service.name;
      // console.log(this.form.service)
      
      this.list_services = []

      this.cart.forEach((oggetto) => {
        let data = {
          'id': oggetto.service.id,
          'quantity': oggetto.quantity
        }
        console.log("FORSE QUESTA E' LA LISTA DEI SERVIZI???")
        console.log(data)
        this.list_services.push(data)
      });

      console.log("jkenfknw")
      console.log(this.list_services)

      // creo json body per il servizio postPriceByServiceAndAffiliate
      let dataPrice = {
          'service': this.list_services,
          'affiliate_user': this.form.affiliate_user
      }
      

      // chiamata metodo per anteprima calcolo prezzo
      await this.postPriceByServiceAndAffiliate(dataPrice);
      // if (!this.price) {
      //   this.showError("Errore durante il calcolo del costo del servizio");
      // }

      // se non ci sono errori con la funzione del calcolo prezzo --> mostra la modale di conferma
      this.$bvModal.show("modal-confirmation");
    },
    onModalConfirm() {
      // preparo la form in un body json per il service
      let data = JSON.parse(JSON.stringify(this.form));

      // assegno alcuni valori manualmente
      data.service = this.list_services;
      data.family_user = this.form.family_user.famiglia_id;
      data.affiliate_user = this.form.affiliate_user;
      data.address = this.form.address;
      data.assisted_name = this.form.assisted_name;
      data.process_status = processStatuses.notProcessed;
      data.caretaker_user = null;
  
      console.log("Submitting service request", data);

      // chiamata al service per la creazione della richiesta servizi
      this.requestService(data);
    },
    resetForm() {
      this.form = this.getDefaultFormData();
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    showModalRegister(){
      router.push({
        name: "register",
        params: {
          profilo: "caregiver"
        }
      });
      
    },
    removeFromCart(index) {
    this.cart.splice(index, 1);
    },
    addToCart(selectedService) {
    const existingItem = this.cart.find(item => item.service.id === selectedService.id);

    if (existingItem) {
      // Se l'elemento esiste già nel carrello, aumenta la quantità
      existingItem.quantity++;
    } else {
      // Se l'elemento non esiste ancora nel carrello, aggiungilo
      this.cart.push({ service: selectedService, quantity: 1 });
    }
  },

  },
  mounted() {
    if(!this.families || !this.families.length){
      console.log("Loading families from API")
      this.getFamilies();
    }
    if (!this.services || !this.services.length) {
      // Load from API
      console.log("Loading services from API");
      this.getall();
    }
    if (!this.cities || !this.cities.length) {
      // Load from API
      console.log("Loading cities from API");
      this.getCities();
    }
  },
};
</script>

<style scoped>
::v-deep .vue__time-picker input {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: "Montserrat", sans-serif;
}

::v-deep .vue__time-picker input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

::v-deep .vue__time-picker.is-invalid input {
  border-color: #dc3545;
}

::v-deep .custom-btn {
  font-size: 10px;
  padding: 4px 8px;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  background-color: #dc3545; /* Cambia il colore di sfondo in rosso per corrispondere al tuo stile */
  border: 1px solid #dc3545; /* Cambia il colore del bordo in rosso per corrispondere al tuo stile */
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: "Montserrat", sans-serif;
}

::v-deep .cart-item {
  margin-bottom: 10px; /* Imposta il margine inferiore desiderato tra gli elementi */
}

</style>
