var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-overlay",
    {
      attrs: {
        show: _vm.loadingPrice || _vm.loadingServiceRequested,
        rounded: "sm"
      }
    },
    [
      _c("h4", { staticClass: "text-primary" }, [
        _vm._v(_vm._s(_vm.$t("services.requests")))
      ]),
      _c(
        "b-form-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "8" } },
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.onFormSubmit($event)
                    }
                  }
                },
                [
                  _c(
                    "b-form-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "12" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "service-input-group",
                                label: _vm.$t("services.service"),
                                "label-for": "service"
                              }
                            },
                            [
                              _c(
                                "multiselect",
                                {
                                  attrs: {
                                    id: "service",
                                    "close-on-select": true,
                                    "clear-on-select": false,
                                    "aria-autocomplete": false,
                                    multiple: false,
                                    "allow-empty": false,
                                    placeholder: _vm.$t("select_placeholder"),
                                    "select-label": _vm.$t(
                                      "multiselect.selectLabel"
                                    ),
                                    "selected-label": _vm.$t(
                                      "multiselect.selectedLabel"
                                    ),
                                    "track-by": "id",
                                    options: _vm.services,
                                    state: _vm.validateState("service"),
                                    "aria-describedby": "service-live-feedback",
                                    "reset-after": true
                                  },
                                  on: {
                                    select: function($event) {
                                      return _vm.addToCart($event)
                                    }
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "option",
                                      fn: function(ref) {
                                        var option = ref.option
                                        return [
                                          _vm._v(
                                            " " + _vm._s(option.name) + " "
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "singleLabel",
                                      fn: function(ref) {
                                        var option = ref.option
                                        return [
                                          _vm._v(
                                            " " + _vm._s(option.name) + " "
                                          )
                                        ]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.$v.form.service.$model,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$v.form.service,
                                        "$model",
                                        $$v
                                      )
                                    },
                                    expression: "$v.form.service.$model"
                                  }
                                },
                                [
                                  _c("template", { slot: "noOptions" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("multiselect.noOptionsLabel")
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("template", { slot: "noResult" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("multiselect.noResultLabel")
                                      ) + " "
                                    )
                                  ])
                                ],
                                2
                              ),
                              _c(
                                "b-form-invalid-feedback",
                                { attrs: { id: "service-live-feedback" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("validation.required")) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.form.service
                    ? _c(
                        "b-form-row",
                        { staticClass: "mb-2" },
                        [
                          _c("b-col", { attrs: { lg: "12" } }, [
                            _c(
                              "div",
                              { staticClass: "p-2 bg-secondary text-white" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.form.service.description) +
                                    " "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.serviceDescription
                    ? _c(
                        "b-form-row",
                        { staticClass: "mb-2" },
                        [
                          _c("b-col", { attrs: { lg: "12" } }, [
                            _c("p", [_vm._v(_vm._s(_vm.serviceDescription))])
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("hr", {
                    staticClass: "my-2",
                    staticStyle: {
                      "border-top": "1.5px solid rgb(200, 200, 200)"
                    }
                  }),
                  _c("br"),
                  _c(
                    "b-form-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "family_user-input-group",
                                label: _vm.$t("services.user_id"),
                                "label-for": "family_user"
                              }
                            },
                            [
                              _c(
                                "multiselect",
                                {
                                  class: {
                                    "is-invalid": _vm.$v.form.family_user.$error
                                  },
                                  attrs: {
                                    id: "family_user",
                                    "close-on-select": true,
                                    "clear-on-select": false,
                                    "aria-autocomplete": false,
                                    multiple: false,
                                    placeholder: _vm.$t("select_placeholder"),
                                    "select-label": _vm.$t(
                                      "multiselect.selectLabel"
                                    ),
                                    "selected-label": _vm.$t(
                                      "multiselect.selectedLabel"
                                    ),
                                    "deselect-label": _vm.$t(
                                      "multiselect.deselectLabel"
                                    ),
                                    "track-by": "famiglia_id",
                                    options: _vm.families,
                                    state: _vm.validateState("family_user"),
                                    "aria-describedby":
                                      "family_user-live-feedback"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "option",
                                      fn: function(ref) {
                                        var option = ref.option
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                option.first_name +
                                                  " " +
                                                  option.last_name
                                              ) +
                                              " "
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "singleLabel",
                                      fn: function(ref) {
                                        var option = ref.option
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                option.first_name +
                                                  " " +
                                                  option.last_name
                                              ) +
                                              " "
                                          )
                                        ]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.$v.form.family_user.$model,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$v.form.family_user,
                                        "$model",
                                        $$v
                                      )
                                    },
                                    expression: "$v.form.family_user.$model"
                                  }
                                },
                                [
                                  _c("template", { slot: "noOptions" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("multiselect.noOptionsLabel")
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("template", { slot: "noResult" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("multiselect.noResultLabel")
                                      ) + " "
                                    )
                                  ])
                                ],
                                2
                              ),
                              _c(
                                "b-form-invalid-feedback",
                                { attrs: { id: "family_user-live-feedback" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("validation.required")) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "button_add_family-input-group",
                                label: _vm.$t("services.add_family"),
                                "label-for": "button_add_family"
                              }
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { id: "button_add_family" },
                                  on: { click: _vm.showModalRegister }
                                },
                                [_vm._v("+")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-form-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "affiliate_user-input-group",
                                label: _vm.$t("services.affiliate_family"),
                                "label-for": "affiliate_user"
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.$v.form.affiliate_user.$model,
                                    expression: "$v.form.affiliate_user.$model"
                                  }
                                ],
                                attrs: { type: "hidden" },
                                domProps: {
                                  value: _vm.$v.form.affiliate_user.$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.form.affiliate_user,
                                      "$model",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _c("b-form-input", {
                                attrs: {
                                  id: "affiliate_user",
                                  type: "text",
                                  value:
                                    _vm.$loggedUser().first_name +
                                    " " +
                                    _vm.$loggedUser().last_name,
                                  disabled: ""
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-form-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "address-input-group",
                                label: _vm.$t("services.address"),
                                "label-for": "address"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "address",
                                  type: "text",
                                  state: _vm.validateState("address"),
                                  "aria-describedby": "address-live-feedback"
                                },
                                model: {
                                  value: _vm.$v.form.address.$model,
                                  callback: function($$v) {
                                    _vm.$set(_vm.$v.form.address, "$model", $$v)
                                  },
                                  expression: "$v.form.address.$model"
                                }
                              }),
                              _c(
                                "b-form-invalid-feedback",
                                { attrs: { id: "address-live-feedback" } },
                                [
                                  !_vm.$v.form.address.required
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("validation.required")
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { lg: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "assisted_name-input-group",
                                label: _vm.$t("services.assisted_name"),
                                "label-for": "assisted_name"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "assisted_name",
                                  type: "text",
                                  state: _vm.validateState("assisted_name"),
                                  "aria-describedby":
                                    "assisted_name-live-feedback"
                                },
                                model: {
                                  value: _vm.$v.form.assisted_name.$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.form.assisted_name,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "$v.form.assisted_name.$model"
                                }
                              }),
                              _c(
                                "b-form-invalid-feedback",
                                {
                                  attrs: { id: "assisted_name-live-feedback" }
                                },
                                [
                                  !_vm.$v.form.assisted_name.required
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("validation.required")
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            type: "submit",
                            variant: "success",
                            disabled: _vm.isCartEmpty
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("buttons.service_request")) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass: "ml-4 text-center",
              staticStyle: { "border-left": "1px solid rgb(200, 200, 200)" },
              attrs: { lg: "3" }
            },
            [
              _c("h4", { staticClass: "text-primary" }, [
                _vm._v(_vm._s(_vm.$t("services.cart")))
              ]),
              _c("div", { staticClass: "cart" }, [
                _c(
                  "ul",
                  _vm._l(_vm.cart, function(cartItem, index) {
                    return _c(
                      "li",
                      { key: index, staticClass: "cart-item" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(cartItem.service.name) +
                            " (x" +
                            _vm._s(cartItem.quantity) +
                            ") "
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "custom-btn",
                            attrs: { variant: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.removeFromCart(index)
                              }
                            }
                          },
                          [_c("b-icon-trash")],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  0
                )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-confirmation",
            title: "Riepilogo",
            "ok-title": "Conferma",
            "cancel-title": "Annulla"
          },
          on: { ok: _vm.onModalConfirm }
        },
        [
          _c("p", { staticClass: "my-4" }, [
            _vm._v(" Prezzo: "),
            _c("strong", [_vm._v(_vm._s(this.price) + "€")])
          ]),
          _c("p", { staticClass: "my-4" }, [
            _vm._v(" Assistito: "),
            _c("strong", [_vm._v(_vm._s(this.form.assisted_name))])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }